import axios from "./http-interceptoer";

export default {
  test(params) {
    return axios.post(
      "/api/client-manage/dahua/behavior-violation/behavior-violation-alarm",
      params
    );
  },
  // 用户登录
  userLogin(params) {
    return axios.post("/api/client-manage/login", params);
  },
  //重置密码
  resetPsd(userId) {
    return axios.get("/api/client-manage/password/reset/" + userId);
  },
  //修改密码
  updatePsd(params) {
    return axios.put("/api/client-manage/password/update", params);
  },
  // 获取验证码
  getCode(sys, mobile) {
    return axios.get("/api/client-manage/login/sendSms/" + sys + "/" + mobile);
  },
  // 用户登出
  logout() {
    return axios.post("/api/client-manage/login/logout");
  },
  //获取权限
  getByLogin() {
    return axios.get("/api/client-manage/roleMenu/getByLogin");
  },
  //电子秤
  getElectronic(canteenId, params) {
    return axios.get(
      "/api/client-manage/screen/listReceivableAndPaid/" + canteenId,
      { params }
    );
  }
};
