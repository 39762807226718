import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/router/login.js'
import switchUsageRecord from '@/router/switchUsageRecord.js'
import unknownPerson from '@/router/unknownPerson.js'
import main from '@/router/main.js'
import bigScreen from '@/router/bigScreen'
import warnCenter from '@/router/warnCenter'

Vue.use(VueRouter)
const routes = [login, switchUsageRecord, unknownPerson, main, bigScreen, warnCenter]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//解决路由地址重复的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
