import Vue from 'vue'
import {
    Alert,
    AutoComplete,
    Carousel,
    Checkbox,
    Divider,
    Layout,
    Tabs,
    Menu,
    Button,
    Icon,
    Popconfirm,
    Popover,
    Progress,
    Input,
    InputNumber,
    Select,
    Switch,
    Radio,
    Modal,
    Row,
    Col,
    DatePicker,
    TimePicker,
    Table,
    Breadcrumb,
    Pagination,
    Message,
    ConfigProvider,
    Upload,
    Form,
    FormModel,
    Steps,
    Transfer,
    Spin,
    Tree,
    List
} from 'ant-design-vue'

Vue.use(AutoComplete)
Vue.use(Alert)
Vue.use(Carousel)
Vue.use(Divider)
Vue.use(Layout)
Vue.use(Tabs)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Button)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Popconfirm)
Vue.use(Popover)
Vue.use(Progress)
Vue.use(Message)
Vue.use(Table)
Vue.use(Breadcrumb)
Vue.use(Pagination)
Vue.use(ConfigProvider)
Vue.use(Upload)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Steps)
Vue.use(Transfer)
Vue.use(Spin)
Vue.use(Tree)
Vue.use(List)

Vue.prototype.$alert = Message
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$info = Modal.info
Vue.prototype.$message = Message
