import axios from "axios";
import { Message } from "ant-design-vue";

const http = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 100000, // 请求超时时间10s
  // headers: {'X-Custom-Header': ''}
  validateStatus(status) {
    switch (status) {
      case 400:
        break;
      case 401:
        Message.warn("授权失败，请重新登录");
        setTimeout(function() {
          window.location.href = "/";
        }, 3000);
        break;
      case 403:
        Message.error("拒绝访问");
        break;
      case 404:
        Message.warn("请求错误,未找到该资源");
        break;
      case 500:
        Message.error("服务器连接失败,请检查网络是否正常");
        break;
    }
    return status >= 200 && status < 300;
  }
});

// req拦截
http.interceptors.request.use(
  config => {
    if (/\/api/.test(config.url)) {
      config.headers = {
        "Content-Type": "application/json;chartset=utf-8",
        ...config.headers
      };
    }
    // 请求头添加token
    if (sessionStorage.getItem("token")) {
      config.headers["Authorization"] =
        "Bearer" + " " + sessionStorage.getItem("token");
    }
    return config;
  },
  error => {
    alert(error);
    console.error("请求错误=====>[params]:", error);
    return Promise.reject(error);
  }
);

// res拦截
http.interceptors.response.use(
  response => {
    // 将请求头中的access.token信息存入缓存
    if (response.headers["access-token"]) {
      sessionStorage.setItem("token", response.headers["access-token"]);
    }
    if (response.headers["content-disposition"]) {
      sessionStorage.setItem(
        "downFileName",
        response.headers["content-disposition"]
      );
    }
    const res = response.data;
    if (res.code === "403") {
      return;
    }
    return res;
  },
  error => {
    return Promise.reject(error);
  }
);

export default http;
