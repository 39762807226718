import index from './index.vue'
// This is the point
const QRcode = {
  // eslint-disable-line
  install: function(Vue) {
    Vue.component('QRcode', index)
  }
}
// Export components
export default QRcode
