export default {
    path: '/main',
    name: 'main',
    component: () => import('@/views/main/Main.vue'),

    children: [
        { path: '/main', redirect: '/home' },
        //公用的头
        {
            path: '/header',
            name: 'header',
            component: () => import('../views/main/components/Header.vue')
        },
        //公用的侧边菜单栏
        {
            path: '/leftMenu',
            name: 'leftMenu',
            component: () => import('../views/main/components/LeftMenu.vue')
        },
        //公用的内容栏
        {
            path: '/rightContent',
            name: 'rightContent',
            component: () => import('../views/main/components/RightContent.vue')
        },
        //home页
        {
            path: '/home',
            name: 'home',
            component: () => import('../views/main/components/Home.vue')
        },
        //-----------云监控-------------------
        //云监控--AI智能识别--非法闯入
        {
            path: '/unknownUser',
            name: 'unknownUser',
            component: () => import('../views/BehaviorControl/UnknownUser.vue')
        },
        //云监控--AI智能识别--行为违规
        {
            path: '/unableRule',
            name: 'unableRule',
            component: () => import('../views/BehaviorControl/UnableRule.vue')
        },
        //云监控--名厨亮灶--在线监控
        {
            path: '/entryName',
            name: 'entryName',
            component: () => import('../views/BehaviorControl/EntryName.vue')
        },
        //云监控--AI设置--抓拍区域
        {
            path: '/grabArea',
            name: 'grabArea',
            component: () => import('../views/BehaviorControl/GrabArea.vue')
        },
        //云监控--AI设置--抓拍区域详情
        {
            path: '/grabAreaDetail',
            name: 'grabAreaDetail',
            component: () => import('../views/BehaviorControl/GrabAreaDetail.vue')
        },
        //云监控--AI设置--AI摄像头
        {
            path: '/AIarea',
            name: 'AIarea',
            component: () => import('../views/BehaviorControl/AIarea.vue')
        },
        //云监控--活物侦测
        {
            path: '/livingTest',
            name: 'livingTest',
            component: () => import('../views/YJK/LivingTest')
        },
        //云监控---着火预警
        {
            path: '/fireWarn',
            name: 'fireWarn',
            component: () => import('../views/YJK/FireWarn')
        },

        //-----------云食安-------------------
        //云食安--员工管理--考勤与晨检
        {
            path: '/attendanceManage',
            name: 'attendanceManage',
            component: () => import('../views/User/AttendanceManage')
        },
        //云食安--员工管理-健康证预警
        {
            path: '/healthWarning',
            name: 'healthWarning',
            component: () => import('../views/User/HealthWarning')
        },
        // 云食安--智能留样-留样取样
        {
            path: '/retentionSampling',
            name: 'retentionSampling',
            component: () => import('../views/RetentionSampling/RetentionSampling'),
            meta: {
                keepAlive: true // 需要缓存
            }
        },
        // 云食安--智能留样-留样取样-详情
        {
            path: '/sampleDetail',
            name: 'sampleDetail',
            component: () => import('@/views/RetentionSampling/SampleDetail.vue')
        },
        {
            path: '/sampleDetail',
            name: 'sampleDetail',
            component: () => import('@/views/RetentionSampling/SampleDetail.vue')
        },
        // 云食安-留样取样-添加详情
        {
            path: '/addSample',
            name: 'addSample',
            component: () => import('@/views/RetentionSampling/AddSample.vue')
        },
        // 云食安--智能留样-应急开门记录
        {
            path: '/urgentRecord',
            name: 'urgentRecord',
            component: () => import('../views/RetentionSampling/UrgentRecord')
        },

        //切配工艺--操作原料标准
        {
            path: '/handleStandard',
            name: 'handleStandard',
            component: () => import('../views/YCD/HandleStandard')
        },

        //编制菜单--原材料信息

        {
            path: '/materialInfo',
            name: 'materialInfo',
            component: () => import('../views/Cookbook/MaterialInfo')
        },
        // 编制菜单 - 菜品类别
        {
            path: '/dishes',
            name: 'dishes',
            component: () => import('../views/Cookbook/Dishes')
        },
        // 编制菜单 - 制定菜品
        {
            path: '/makeDishes',
            name: 'makeDishes',
            component: () => import('../views/Cookbook/MakeDishes')
        },
        //制定菜品 - 查看菜品详情
        {
            path: '/dishesDetail',
            name: 'dishesDetail',
            component: () => import('../views/Cookbook/DishesDetail')
        },
        //制定菜品 - 新增、编辑菜品
        {
            path: '/handleDishes',
            name: 'handleDishes',
            component: () => import('../views/Cookbook/HandleDishes')
        },
        // 编制菜单 - 菜谱明细
        {
            path: '/menuDetail',
            name: 'menuDetail',
            component: () => import('../views/Cookbook/MenuDetail')
        },
        // 编制菜单 - 菜谱明细 - 查看
        {
            path: '/menuDetailInfo',
            name: 'menuDetailInfo',
            component: () => import('../views/Cookbook/components/MenuInfo')
        },
        // 编制菜单 - 发布菜谱-结算预览dayDishesDtoList
        {
            path: '/menuPreview',
            name: 'menuPreview',
            component: () => import('../views/Cookbook/MenuPreview')
        },
        //编制菜单 - 发布菜谱
        {
            path: '/publicMenu',
            name: 'publicMenu',
            component: () => import('../views/Cookbook/PublicMenu'),
            meta: {
                keepAlive: true // 需要缓存
            }
        },
        // 库存管理 - 采购管理
        {
            path: '/purchaseManage',
            name: 'purchaseManage',
            component: () => import('../views/Storage/PurchaseManage')
        },
        // 库存管理 - 收货单
        {
            path: '/receiptManage',
            name: 'receiptManage',
            component: () => import('../views/Storage/ReceiptManage')
        },
        // 库存管理 - 收货单-详情
        {
            path: '/receiptDetail',
            name: 'receiptDetail',
            component: () => import('../views/Storage/components/ReceiptDetail')
        },
        // 采购订货 - 订单管理-编辑/新增
        {
            path: '/handlePurchase',
            name: 'handlePurchase',
            component: () => import('../views/Storage/components/HandlePurchase')
        },
        // 采购订货 -- 订单管理-详情
        {
            path: '/purchaseDetail',
            name: 'purchaseDetail',
            component: () => import('../views/Storage/components/PurchaseDetail')
        },
        // 智能溯源秤 -
        {
            path: '/receiveRecord',
            name: 'receiveRecord',
            component: () => import('../views/Storage/ReceiveRecord')
        },
        // 智能溯源秤 - 详情
        {
            path: '/receiveRecordDetail',
            name: 'receiveRecordDetail',
            component: () => import('../views/Storage/components/ReceiveRecordDetail')
        },
        // 库存管理 - 出库明细
        {
            path: '/outLibraryManage',
            name: 'outLibraryManage',
            component: () => import('../views/Storage/OutLibraryManage')
        },
        // 库存管理 - 入库管理
        {
            path: '/warehouseManage',
            name: 'warehouseManage',
            component: () => import('../views/Storage/WarehouseManage')
        },
        {
            path: '/handleInventory',
            name: 'handleInventory',
            component: () => import('../views/Storage/components/HandleInventory')
        },
        // 库存管理 - 入库管理 -入库
        {
            path: '/handleWarehouse',
            name: 'handleWarehouse',
            component: () => import('../views/Storage/components/HandleWarehouse')
        },

        // 采购订货 -- 供应商管理
        {
            path: '/supplierManage',
            name: 'supplierManage',
            component: () => import('../views/Storage/SupplierManage')
        },
        // 采购订货 - 供应商管理、编辑/新增
        {
            path: '/handleSupplierMange',
            name: 'handleSupplierMange',
            component: () => import('../views/Storage/components/HandleSupplierMange')
        },
        // 采购订货 - 供应商管理 - 详情
        {
            path: '/supplierDetail',
            name: 'supplierDetail',
            component: () => import('../views/Storage/components/SupplierDetail')
        },
        {
            path: '/purchaseLiquidation',
            name: 'purchaseLiquidation',
            component: () => import('../views/Storage/PurchaseLiquidation')
        },
        // 库存管理---库存盘点
        {
            path: '/inventoryCheck',
            name: 'inventoryCheck',
            component: () => import('../views/YCD/InventoryCheck')
        },
        //原料统计--原材料汇总
        {
            path: '/collectMaterial',
            name: 'collectMaterial',
            component: () => import('../views/YCD/CollectMaterial')
        },
        //-----------------云物联-----------------
        //old menu 线上无 start
        // 环境检测 - 设备巡检
        {
            path: '/equipInspection',
            name: 'equipInspection',
            component: () => import('../views/Environmental/EquipInspection')
        },
        // 环境检测 - 设备巡检-详情
        {
            path: '/equipInspectionDetail',
            name: 'equipInspectionDetail',
            component: () => import('../views/Environmental/EquipInspectionDetail')
        },
        //新建/编辑 - 设备巡检
        {
            path: '/handleEquip',
            name: 'handleEquip',
            component: () => import('../views/Environmental/HandleEquip')
        },

        //云物联---- 温湿度管控
        {
            path: '/temperatureControl',
            name: 'temperatureControl',
            component: () => import('../views/Environmental/TemperatureControl')
        },
        //云物联 温湿度管控-设备详情
        {
            path: '/tempControlDetail',
            name: 'tempControlDetail',
            component: () => import('../views/Environmental/TempControlDetail.vue')
        },
        // 云物联--温湿度管控--报警信息
        {
            path: '/temperatureAlarmInfo',
            name: 'temperatureAlarmInfo',
            component: () => import('../views/Environmental/TemperatureAlarmInfo')
        },
        // 云物联 ---瓦斯管控-- 瓦斯设备信息
        {
            path: '/gasEquipInfo',
            name: 'gasEquipInfo',
            component: () => import('../views/Environmental/GasEquipInfo')
        },
        // 云物联 --瓦斯管控-- 瓦斯报警信息
        {
            path: '/gasAlarmInfo',
            name: 'gasAlarmInfo',
            component: () => import('../views/Environmental/GasAlarmInfo')
        },
        // 云物联 -- 阀门设备信息
        {
            path: '/gasValveEquipInfo',
            name: 'gasValveEquipInfo',
            component: () => import('../views/Environmental/GasValveEquipInfo')
        },
        // 云物联 -- 门窗预警
        {
            path: '/doorWindowWarn',
            name: 'doorWindowWarn',
            component: () => import('../views/Environmental/DoorAndWindowWarning')
        },
        ///-----------基础设置模块-----------------------
        //基础设置--角色管理
        {
            path: '/roleManage',
            name: 'roleManage',
            component: () => import('../views/User/RoleManage')
        },
        //基础设置--角色管理-新建角色
        {
            path: '/createRole',
            name: 'createRole',
            component: () => import('../views/User/components/CreateRole')
        },
        //基础设置--角色管理-编辑角色
        {
            path: '/editRole',
            name: 'editRole',
            component: () => import('../views/User/components/EditRole')
        },
        //基础设置--角色管理-角色详情
        {
            path: '/roleDetail',
            name: 'roleDetail',
            component: () => import('../views/User/components/RoleDetail')
        },
        //基础设置--组织管理
        {
            path: '/organizationList',
            name: 'organizationList',
            component: () => import('../views/Organization/OrganizationList')
        },
        //基础设置--组织管理详情
        {
            path: '/organizationDetail',
            name: 'organizationDetail',
            component: () => import('../views/Organization/OrganizationDetail')
        },
        //基础设置--组织管理员
        {
            path: '/organizationAdminList',
            name: 'organizationAdminList',
            component: () => import('../views/OrganizationAdmin/OrganizationAdminList')
        },
        //基础设置--组织管理员详情
        {
            path: '/organizationAdminDetail',
            name: 'organizationAdminDetail',
            component: () => import('../views/OrganizationAdmin/OrganizationAdminDetail')
        },
        //基础设置--编辑/新增组织管理员
        {
            path: '/handleOrganizationAdmin',
            name: 'handleOrganizationAdmin',
            component: () => import('../views/OrganizationAdmin/handleOrganizationAdmin')
        },
        //基础设置--编辑/新增组织管理
        {
            path: '/handleOrganization',
            name: 'handleOrganization',
            component: () => import('../views/Organization/handleOrganization')
        },
        //基础设置--员工管理
        {
            path: '/staffManage',
            name: 'staffManage',
            component: () => import('../views/User/StaffManage')
        },
        //基础设置--员工管理-新建员工
        {
            path: '/createStaff',
            name: 'createStaff',
            component: () => import('../views/User/components/CreateStaff')
        },
        //基础设置--员工管理-编辑员工
        {
            path: '/editStaff',
            name: 'editStaff',
            component: () => import('../views/User/components/EditStaff')
        },
        //基础设置--员工管理-员工详情
        {
            path: '/staffDetai',
            name: 'staffDetai',
            component: () => import('../views/User/components/StaffDetail')
        },
        //基础设置--餐次管理
        {
            path: '/mealMange',
            name: 'mealMange',
            component: () => import('../views/Basic/MealMange')
        },
        //基础设置--编辑/新增餐次
        {
            path: '/handleMeal',
            name: 'handleMeal',
            component: () => import('../views/Basic/handleMeal')
        },
        //基础设置--班次管理
        {
            path: '/shiftMange',
            name: 'shiftMange',
            component: () => import('../views/Basic/ShiftMange')
        },
        //基础设置--编辑/新增班次
        {
            path: '/handleShift',
            name: 'handleShift',
            component: () => import('../views/Basic/handleShift')
        },
        //基础设置--班次管理--班次详情
        {
            path: '/shiftMangeDetail',
            name: 'shiftMangeDetail',
            component: () => import('../views/Basic/ShiftMangeDetail')
        },
        //基础设置--仓库管理
        {
            path: '/storeManage',
            name: 'storeManage',
            component: () => import('../views/Basic/StoreManage')
        },
        //基础设置--档口档案
        {
            path: '/stallManage',
            name: 'stallManage',
            component: () => import('../views/Basic/StallManage')
        },
        //基础设置--临时人员管理
        {
            path: '/temporaryUserManage',
            name: 'temporaryUserManage',
            component: () => import('../views/Basic/TemporaryUserManage')
        },
        //基础设置--临时人员管理 -新增/编辑
        {
            path: '/handleTemporaryUser',
            name: 'handleTemporaryUser',
            component: () => import('../views/Basic/HandleTemporaryUser')
        },
        //基础设置--食堂管理
        {
            path: '/canteen',
            name: 'Canteen',
            component: () => import('../views/Canteen/canteen')
        },
        //基础设置--食堂管理详情
        {
            path: '/canteenDetail',
            name: 'canteenDetail',
            component: () => import('../views/Canteen/canteenDetail')
        },
        //基础设置--编辑/新增食堂管理
        {
            path: '/handleCanteen',
            name: 'handleCanteen',
            component: () => import('../views/Canteen/handleCanteen')
        },
        //基础设置--用户管理 之前的是员工管理 这里是食堂负责人
        {
            path: '/managersList',
            name: 'managersList',
            component: () => import('../views/ManagersList/managersList')
        },
        //基础设置--用户详情
        {
            path: '/managersDetail',
            name: 'managersDetail',
            component: () => import('../views/ManagersList/managersDetail')
        },
        //基础设置--编辑/新增用户
        {
            path: '/handleManagers',
            name: 'handleManagers',
            component: () => import('../views/ManagersList/handleManagers')
        },
        //-------------------设备管理--------------------------
        //设备管理--ai摄像头设备列表
        {
            path: '/aiCameraDevice',
            name: 'aiCameraDevice',
            component: () => import('../views/SBGL/AiCameraDevice')
        },
        //设备管理--编辑、新增、详情ai摄像头设备
        {
            path: '/handleAiCamera',
            name: 'handleAiCamera',
            component: () => import('../views/SBGL/HandleAiCamera')
        },
        //设备管理--智能称设备列表
        {
            path: '/scaleDevice',
            name: 'scaleDevice',
            component: () => import('../views/SBGL/ScaleDevice')
        },
        //设备管理--编辑、新增、智能称设备
        {
            path: '/handleScale',
            name: 'handleScale',
            component: () => import('../views/SBGL/HandleScale')
        },
        //设备管理--留样柜设备列表
        {
            path: '/sampleDevice',
            name: 'sampleDevice',
            component: () => import('../views/SBGL/SampleDevice')
        },
        //设备管理--编辑、新增、留样柜设备
        {
            path: '/handleSample',
            name: 'handleSample',
            component: () => import('../views/SBGL/HandleSample')
        },
        //设备管理--考勤机设备列表
        {
            path: '/attendDevice',
            name: 'attendDevice',
            component: () => import('../views/SBGL/AttendDevice')
        },
        //设备管理--考勤机设备编辑、新增、详情
        {
            path: '/handleAttend',
            name: 'handleAttend',
            component: () => import('../views/SBGL/HandleAttend')
        },
        //---------------大屏中心-----------------
        //大屏中心/大屏中心
        {
            path: '/screenCenter',
            name: 'screenCenter',
            component: () => import('../views/ScreenCenter/screenCenter')
        },
        //大屏中心/数据分析
        {
            path: '/dataAnalysis',
            name: 'dataAnalysis',
            component: () => import('../views/ScreenCenter/DataAnalysis')
        },
        // 门禁
        {
            path: '/accessCheckManage',
            name: 'accessCheckManage',
            component: () => import('../views/EntranceGuard/EntranceGuard')
        },
        //---------------预警中心-----------------
        {
            path: '/alarmScreen',
            name: 'alarmScreen',
            component: () => import('@/views/warnCenter/WarnCenter')
        },
        //食材过期预警
        {
            path: '/overTimeWarn',
            name: 'overTimeWarn',
            component: () => import('../views/warnCenter/OverTimeWarn')
        },
        //---------------监督巡检-----------------
        //部门管理
        {
            path: '/patrolDeptManage',
            name: 'patrolDeptManage',
            component: () => import('@/views/Inspect/patrolDeptManage')
        },
        //岗位管理
        {
            path: '/patrolPostManage',
            name: 'patrolPostManage',
            component: () => import('@/views/Inspect/patrolPostManage')
        },
        //员工管理
        {
            path: '/patrolStaffManage',
            name: 'patrolStaffManage',
            component: () => import('@/views/Inspect/patrolStaffManage')
        },
        //检查项库
        {
            path: '/patrolCheckItem',
            name: 'patrolCheckItem',
            component: () => import('@/views/Inspect/patrolCheckItem')
        },
        //流程库
        {
            path: '/processView',
            name: 'processView',
            component: () => import('@/views/Inspect/processView')
        },
        //新增------流程库
        {
            path: '/patrolProcess',
            name: 'patrolProcess',
            component: () => import('@/views/Inspect/patrolProcess')
        },
        //模板库
        {
            path: '/patrolTemplate',
            name: 'patrolTemplate',
            component: () => import('@/views/Inspect/patrolTemplate')
        },
        //新增------模板库
        {
            path: '/editTemplate',
            name: 'editTemplate',
            component: () => import('@/views/Inspect/editTemplate')
        },
        //管理------模板库
        {
            path: '/manageTemplate',
            name: 'manageTemplate',
            component: () => import('@/views/Inspect/manageTemplate')
        },
        //工单管理
        {
            path: '/patrolWorkOrder',
            name: 'patrolWorkOrder',
            component: () => import('@/views/Inspect/patrolWorkOrder')
        },
        //工单管理---管理
        {
            path: '/manageWork',
            name: 'manageWork',
            component: () => import('@/views/Inspect/manageWork')
        },
        //工单详情---管理
        {
            path: '/patrolWorkOrderDetail',
            name: 'patrolWorkOrderDetail',
            component: () => import('@/views/Inspect/patrolWorkOrderDetail')
        },
        //新增------流程库
        {
            path: '/addNewWork',
            name: 'addNewWork',
            component: () => import('@/views/Inspect/addNewWork')
        },
        //我的工单
        {
            path: '/myWorkOrder',
            name: 'myWorkOrder',
            component: () => import('@/views/Inspect/myWorkOrder')
        },
        //我的工单---处理工单
        {
            path: '/fillForm',
            name: 'fillForm',
            component: () => import('@/views/Inspect/fillForm')
        },
        //我的工单---审批工单
        {
            path: '/judgeForm',
            name: 'judgeForm',
            component: () => import('@/views/Inspect/judgeForm')
        },
        //我的信息
        {
            path: '/myInfo',
            name: 'myInfo',
            component: () => import('@/views/Inspect/myInfo')
        },
        //统计分析
        {
            path: '/statisticalService',
            name: 'statisticalService',
            component: () => import('@/views/Inspect/statisticalService')
        },
        //统计分析
        {
            path: '/msCanteen',
            name: 'msCanteen',
            component: () => import('@/views/Inspect/msCanteen')
        },
        //智能开关
        {
            path: '/autoSwitch',
            name: 'autoSwitch',
            component: () => import('@/views/switchDevice/SwitchDeviceManage')
        },
        //---------------供应商平台-----------------
        //商品管理
        {
            path: '/goodsManage',
            name: 'goodsManage',
            component: () => import('@/views/supply/GoodsManage')
        },
        //商品类别管理
        {
            path: '/goodsTypeManage',
            name: 'goodsTypeManage',
            component: () => import('@/views/supply/GoodsTypeManage')
        }
    ]
}
