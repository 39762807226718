<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <keep-alive>
        <router-view v-if="isRouterAlive" :key="$route.fullPath" />
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import '@/assets/css/global.less'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { mapMutations } from 'vuex'

moment.locale('zh-cn')
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      zh_CN
    }
  },
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('smartStore')) {
      this.$store.replaceState(
        Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('smartStore')))
      )
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('smartStore', JSON.stringify(this.$store.state))
    })
  },
  mounted() {
    //初始化表格
    this.tableHeight(this.bxPageInit())

    window.addEventListener(
      'resize',
      () => {
        this.windowHeight = document.documentElement.clientHeight
        if (this.windowHeight > 900) {
          let height = document.body.clientHeight - 450
          this.tableHeight(height)
        } else {
          let height = document.body.clientHeight - 350
          this.tableHeight(height)
        }
      },
      false
    )
  },
  methods: {
    ...mapMutations(['tableHeight']),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style lang="less">
/**定义字体 */
@font-face {
  font-family: 'HemiHeadBdIt';
  src: url('assets/fonts/hemi_head_bd_it.ttf');
}
@font-face {
  font-family: 'PangMenZhengDao';
  src: url('assets/fonts/庞门正道标题体2.0增强版.ttf');
}
@font-face {
  font-family: 'puhuiti';
  src: url('assets/fonts/Alibaba-PuHuiTi-Regular.ttf');
}
@font-face {
  font-family: 'puhuiti-medium';
  src: url('assets/fonts/Alibaba-PuHuiTi-Medium.ttf');
}
@font-face {
  font-family: 'puhuiti-light';
  src: url('assets/fonts/Alibaba-PuHuiTi-Light.ttf');
}
@font-face {
  font-family: 'din';
  src: url('assets/fonts/din_medium.otf');
}
@font-face {
  font-family: 'din-bold';
  src: url('assets/fonts/din_bold.otf');
}
@font-face {
  font-family: 'Source Han Sans CN_Bold';
  src: url('assets/fonts/SourceHanSansCN-Bold.otf');
}
@font-face {
  font-family: 'Source Han Sans CN_Medium';
  src: url('assets/fonts/SourceHanSansCN-Medium.otf');
}
/*修改alert和confirm样式*/
</style>
