import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import patrol from './modules/patrol.js'

let initState = {
    portalList: [],
    portalTitle: '',
    basicTitle: '',
    curRoleMenu: [],
    activeKey: '',
    lastCheckedList: [],
    curUniversityCanteenName: '',
    curUniversityCanteen: '',
    curUniversityId: '',
    menuId: '',
    seleckedMenuKey: [], //选择的菜单key
    openMenuKey: [], //菜单展开的key
    userRights: [], // 用户角色权限组,
    canteenRightSwitchList: [], // 描述用户所管理的是食堂，是否开启管理分权{canteenId:0,rightManage:true}
    tableHeight: 400 // 初始表格高度
}
let cloneDeep = data => {
    return JSON.parse(JSON.stringify(data))
}
Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: cloneDeep(initState),
    getters: {},
    mutations: {
        setPortalList(state, payload) {
            state.portalList = payload.childList
            state.portalTitle = payload.menuName
        },
        setBasicData(state, payload) {
            state.basicTitle = payload
        },
        clearAllDate(state) {
            Object.assign(state, initState)
        },
        getCurRoleAllmenu(state, payload) {
            state.activeKey = payload.activeKey
            state.curRoleMenu = payload.list
        },
        upDateMenuTree(state, payload) {
            state.activeKey = payload.activeKey
            state.curRoleMenu = payload.list
        },
        updateLastCheckedList(state, payload) {
            state.lastCheckedList = payload
        },
        //当前大学的食堂curUniversityId
        GETCURUNIVERSITYID(state, payload) {
            state.curUniversityId = payload
        },
        //当前选中目录的curmenuId
        getCurMenuId(state, payload) {
            state.menuId = payload
        },
        //当前大学的食堂curUniversityCanteen
        GETCURUNIVERSITYCANTEEN(state, payload) {
            state.curUniversityCanteen = payload.canteenId
            state.curUniversityCanteenName = payload.canteenName
        },
        //更新tableHeight
        tableHeight(state, payload) {
            state.tableHeight = payload
        },
        //更新选中菜单key
        updateSelectKey(state, payload) {
            state.seleckedMenuKey = [payload]
        },
        //更新展开的菜单key
        updateOpenKey(state, payload) {
            state.openMenuKey = payload
        },
        //更新展开的菜单key
        updateUserRights(state, payload) {
            state.userRights = payload
        },
        //描述用户所管理的是食堂，是否开启管理分权{canteenId:0,rightManage:true}
        updateCanteenRightSwitchList(state, payload) {
            state.canteenRightSwitchList = payload
        }
    },
    actions: {
        changePortal({ commit }) {
            return commit('setPortalList')
        }
    },
    modules: { user, patrol }
})
