import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'font-awesome/css/font-awesome.min.css'

import './plugins/ant-design-vue'
import './plugins/element-ui'

import mixin from './mixin/mixin'

Vue.mixin(mixin)

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

import moment from 'moment'

window.moment = moment

import animated from 'animate.css'

Vue.use(animated)

import VueDND from 'awe-dnd'

Vue.use(VueDND)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)

import FlowChart from 'flowchart-vue'

Vue.use(FlowChart)

import duoImageViewer from 'duo-image-viewer'

Vue.use(duoImageViewer)

import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

import QRcode from './components/QRcode'

Vue.use(QRcode)

import weiPhoto from 'wei-photo'
import 'wei-photo/style/index.css'

Vue.use(weiPhoto)

//正式环境
Vue.prototype.$imgPrefix = 'https://www.boxisz.com/' //图片地址前缀
Vue.prototype.$socketfix = 'wss://www.boxisz.com/api' //长连接前缀
Vue.prototype.$qrCodeUrl = 'https://www.boxisz.com/switchUsageRecord' //二维码地址

// 测试环境
// Vue.prototype.$imgPrefix = 'https://beta.boxisz.com/' //图片地址前缀
// Vue.prototype.$socketfix = 'wss://beta.boxisz.com/api' //长连接前缀
// Vue.prototype.$qrCodeUrl = 'https://beta.boxisz.com/switchUsageRecord' //二维码地址

// 本地测试环境
// Vue.prototype.$imgPrefix = 'http://192.168.146.128:8000/' //图片地址前缀
// Vue.prototype.$socketfix = 'ws://192.168.146.128:8000/api' //长连接前缀
// Vue.prototype.$qrCodeUrl = 'http://192.168.2.9:8080/switchUsageRecord' //二维码地址

Vue.config.productionTip = false

import './directives/permission'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
