import { mapMutations, mapState } from 'vuex'
const mixin = {
    data() {
        return {
            watchFlag: false,
            sysOptRights: [
                'bom_right',
                'warehouse_rihgt',
                'purchase_order_right',
                'role_right',
                'stall_right',
                'user_right',
                'supplier_right'
            ]
        }
    },
    computed: {
        ...mapState({
            canteenRoleRightManage: state => state.canteenRoleRightManage,
            canteenRightSwitchList: state => state.canteenRightSwitchList
        })
    },
    watch: {
        $route: {
            handler(to) {
                if (this.$route.name == to.name) {
                    // 添加 $nextTick 确保请求 在 activated 之后
                    this.$nextTick(() => {
                        // this.watchFlag && this.init && this.init();
                    })
                } else {
                    this.$destroy()
                    this.GETCURUNIVERSITYCANTEEN({ canteenId: '', canteenName: '' })
                }
            }
        },
        deep: true
    },
    activated() {
        this.watchFlag = true
    },
    deactivated() {
        this.watchFlag = false
    },
    filters: {
        SexFilter(sex) {
            return sex == 1 ? '男' : '女'
        },
        frequencyTypeFilter(type) {
            let val = ''
            switch (type) {
                case 1:
                    val = '日'
                    break
                case 2:
                    val = '周'
                    break
                case 3:
                    val = '月'
                    break
                case 4:
                    val = '季度'
                    break
                case 5:
                    val = '年'
                    break
            }
            return val
        }
    },
    methods: {
        ...mapMutations(['GETCURUNIVERSITYCANTEEN']),
        //上传文件转类型
        getBase64(img, callback) {
            const reader = new FileReader()
            reader.addEventListener('load', () => callback(reader.result))
            reader.readAsDataURL(img)
        },
        //图片上传限制
        imgBeforeUpload(file) {
            const isJpgOrPng =
                file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
            if (!isJpgOrPng) {
                this.$info({ title: '照片格式为jpeg,png,jpg格式!' })
            }
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$info({ title: '图片大小请不要大于2M!' })
            }
            return isJpgOrPng && isLt2M
        },
        imgBeforeUploadStrict(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg'
            if (!isJpgOrPng) {
                this.$info({ title: '照片格式为jpeg或jpg格式!' })
            }
            const isLt2M = file.size / 1024 >= 30 && file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$info({ title: '图片大小需为30kb~2M!' })
            }
            return isJpgOrPng && isLt2M
        },
        //导出
        bxExportMoreXml(msg) {
            let url = window.URL.createObjectURL(msg)
            let a = document.createElement('a')
            document.body.appendChild(a)
            let _name = sessionStorage.getItem('downFileName')
            let fileName =
                (_name &&
                    _name
                        .split(';')[1]
                        .split('=')[1]
                        .split("''")[1]) ||
                ''
            a.href = url
            a.download = decodeURI(fileName)
            a.click()
            window.URL.revokeObjectURL(url)
            sessionStorage.removeItem('downFileName')
        },
        //pageInit初始化不明人员表格高度
        bxPageInit() {
            let windowHeight = document.documentElement.clientHeight
            let height = 0
            if (windowHeight > 900) {
                height = document.body.clientHeight - 450
            } else {
                height = document.body.clientHeight - 350
            }
            return height
        },
        //pageInit初始化old表格高度
        bxOldPageInit() {
            let windowHeight = document.documentElement.clientHeight
            let height = 0
            if (windowHeight > 900) {
                height = 670
            } else {
                height = 270
            }
            return height
        },

        /**
         * 食堂是否开启权限验证
         * @param {} canteenId
         */
        isOpenVerify(canteenId) {
            if (!Number.isInteger(canteenId) || canteenId < 1) {
                return false
            }
            for (let item of this.canteenRightSwitchList) {
                if (item.canteenId == canteenId) {
                    return item.rightManage
                }
            }
            return false
        },

        /**
         * 权限操作
         * 权限在系统里是角色绑定的，每个账户有两个角色：
         * （1）系统角色
         * （2）自定义角色
         * 当用户拥有自定义角色带来的权限时，校验时候需要拥有对应权限。
         * @param {*} userRights 用户拥有的权限组
         * @param {*} funRight 该次验证需要的权限
         */
        optRightVerify(userRights, funRight, canteenId) {
            if (!Number.isInteger(canteenId) || canteenId < 1) {
                return false
            }

            let existed = false
            for (let item of this.canteenRightSwitchList) {
                if (item.canteenId == canteenId) {
                    existed = true
                    if (item.rightManage) {
                        break
                    } else {
                        return true
                    }
                }
            }
            if (!existed) {
                return true
            }
            if (!Array.isArray(userRights) || userRights.length < 1) {
                return false
            }
            if (typeof funRight != 'string') {
                return false
            }
            for (let item of userRights) {
                if (item == funRight) {
                    return true
                }
            }
            return false
        }
        //end
    }
}
export default mixin
