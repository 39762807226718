const state = {
  patrolCanteenId: 0
}
const mutations = {
  setPatrolCanteenId(state, payload) {
    if (payload != null && !isNaN(payload)) {
      state.patrolCanteenId = payload
    }
  }
}
const actions = {
  async SET_PATROL_CANTEEN_ID({ commit }, payload) {
    commit('setPatrolCanteenId', payload)
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
