//permission.js 按钮权限
import Vue from 'vue'
import router from '@/router'
import store from '../store/index'

const hasPermission = (userPermission, level1Name) => {
  let curPath = (level1Name && level1Name) || router.history.current.name
  // let btnPermissionList = JSON.parse(sessionStorage.getItem("USERROLEMENU"));
  let btnPermissionList = store.state.user.mainPortal
  let curPortalTitle = store.state.portalTitle
  curPath = curPath.toLowerCase()
  if (btnPermissionList) {
    let eglishPermission = []
    if (curPortalTitle !== '基础设置') {
      //curPortalTitle
      let firstPat = btnPermissionList.find(c => c.menuName == curPortalTitle)
      firstPat.childList &&
        firstPat.childList.forEach(v => {
          if (v.childList && v.childList.find(m => m.path && m.path.toLowerCase() == curPath)) {
            eglishPermission =
              v.childList.find(m => m.path && m.path.toLowerCase() == curPath).buttonSet || []
            return false
          } else {
            if (v.path.toLowerCase() == curPath) {
              eglishPermission = v.buttonSet || ['read']
              return false
            }
          }
        })
    } else {
      let firstPat = btnPermissionList.find(c => c.menuName == curPortalTitle)
      firstPat.childList &&
        firstPat.childList.forEach(v => {
          if (v.path && v.path.toLowerCase() == curPath) {
            eglishPermission = v.buttonSet || ['read']
            return false
          }
        })
    }
    // 是否在权限数组里面
    let status = false
    if (eglishPermission.length) {
      status = eglishPermission.includes(userPermission)
    }
    return status
  } else {
    return false
  }
}
//全局方法挂载
Vue.prototype.$_has = hasPermission
