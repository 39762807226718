import api from '../../mock/services/login'
const state = {
  mainPortal: [],

  canteenManager: {
    curSelectedCanteenIndex: 0, // 当前操作的食堂在数组中的位置
    supervisorMode: 1, // 针对学校切换的模式:1表示菜单列表形式，2表示搜索框模式
    canteenList: []
  }
}
// 定义好请求的方法,这里将把后台返回的按钮权限数组储存在state里面，或者本地缓存里面
const mutations = {
  //获得按钮权限数组
  getUserPermission(state) {
    sessionStorage.setItem('USERROLEMENU', JSON.stringify(state.mainPortal))
  },
  setCanteenList(state, payload) {
    state.canteenManager.canteenList = payload.canteenList
  },
  setSelectedCanteen(state, payload) {
    state.canteenManager.curSelectedCanteenIndex = payload.curSelectedCanteenIndex
  }
}
// 触发mutaitions里面定义好的方法
const actions = {
  // 定义好请求的方法,这里将把后台返回的按钮权限数组储存在state里面，或者本地缓存里面
  async GET_USER_PERMISSION({ commit, state }) {
    let res = await api.getByLogin()
    if (res && res.success) {
      state.mainPortal = res.results
      commit('getUserPermission')
    }
  }
}
const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
