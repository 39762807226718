export default {
    path: '/dxqMain',
    name: 'dxqMain',
    component: () => import('@/views/BigScreen/dxq/dxqMain'),
    children: [
        {
            path: '/bigScreen', //留样数据
            name: 'bigScreen',
            component: () => import('@/views/BigScreen/dxq/BigScreen')
        },
        {
            path: '/dataCenter', //数据中心
            name: 'dataCenter',
            component: () => import('@/views/BigScreen/dxq/dataCenters.vue')
        },
        {
            path: '/qklView', //区块链可视化界面
            name: 'qklView',
            component: () => import('views/RetentionSampling/qklviews')
        },
        {
            path: '/canteenSelction', //智慧运营平台,页面不对
            name: 'canteenSelction',
            component: () => import('@/views/OperationPlatform/canteenSelection.vue')
        },
    ]
}
